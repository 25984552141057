<template>
  <div id="app">
    <div class="haeder">
      <img src="./assets/gaokao.jpg" alt="" />
    </div>
    <div class="body">
      <el-form v-loading="loading" element-loading-text="加载中" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)" label-position="top" label-width="80%" class="demo-ruleForm"
        :rules="rules" :model="rulesForm" status-icon ref="ruleForm">
        <el-form-item label="考生姓名" prop="real_name">
          <el-input type="text" placeholder="请输入考生姓名" v-model="rulesForm.real_name"></el-input>
        </el-form-item>

        <el-form-item label="考生准考证号" prop="ksh_zhkzh">
          <el-input type="number" placeholder="请输入考生准考证号" v-model="rulesForm.ksh_zhkzh"></el-input>
        </el-form-item>

        <el-form-item label="考生联系电话" prop="phone">
          <el-input type="number" placeholder="请输入考生联系电话" v-model="rulesForm.phone"></el-input>
        </el-form-item>

        <el-form-item label="考生父母联系电话" prop="fumu_phone">
          <el-input type="number" placeholder="请输入考生父母联系电话" v-model="rulesForm.fumu_phone"></el-input>
        </el-form-item>

        <el-form-item label="考生性别" prop="gender">
          <el-radio-group v-model="rulesForm.gender">
            <el-radio label="1">男</el-radio>
            <el-radio label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="考生所在地" prop="address">
          <el-input type="text" placeholder="请输入考生所在地" v-model="rulesForm.address"></el-input>
        </el-form-item>

        <el-form-item label="考生毕业高中" prop="by_gz">
          <el-input type="text" placeholder="请输入考生毕业高中" v-model="rulesForm.by_gz"></el-input>
        </el-form-item>

        <el-form-item label="考生健康状况" prop="jt_qk">
          <el-tag type="text">备注:部分院校对考生健康有要求，请如实填写</el-tag>
          <el-input type="text" placeholder="请输入考生健康状况" v-model="rulesForm.jt_qk"></el-input>
        </el-form-item>
        <el-form-item label="家庭经济情况" prop="jk_zhk">
          <el-input type="text" placeholder="请输入家庭经济情况" v-model="rulesForm.jk_zhk"></el-input>
        </el-form-item>

        <el-form-item label="考生理想大学" prop="lx_dx">
          <el-input type="text" placeholder="请输入考生理想大学" v-model="rulesForm.lx_dx"></el-input>
        </el-form-item>

        <el-form-item label="考生向往城市" prop="xw_chs">
          <el-input type="text" placeholder="请输入考生向往城市" v-model="rulesForm.xw_chs"></el-input>
        </el-form-item>

        <el-form-item label="考生性格爱好" prop="hobby">
          <el-input type="text" placeholder="请输入考生性格爱好" v-model="rulesForm.hobby"></el-input>
        </el-form-item>

        <el-form-item label="学科类别" prop="xk_lb">
          <el-radio-group v-model="rulesForm.xk_lb">
            <el-radio label="理工类">理工类</el-radio>
            <el-radio label="文史类">文史类</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="计划类别" prop="jh_lb">
          <el-radio-group v-model="rulesForm.jh_lb">
            <el-radio label="单列类（选考外语）">单列类（选考外语）</el-radio>
            <el-radio label="单列类（选考民族语文）">单列类（选考民族语文）</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="高考总成绩" prop="gk_zchj">
          <el-input type="number" placeholder="请输入高考总成绩" v-model="rulesForm.gk_zchj"></el-input>
        </el-form-item>

        <el-form-item label="高考位次" prop="gk_wc">
          <el-input type="number" placeholder="请输入高考位次" v-model="rulesForm.gk_wc"></el-input>
        </el-form-item>

        <el-form-item label="语文成绩" prop="yw_chj">
          <el-input type="number" placeholder="请输入语文成绩" v-model="rulesForm.yw_chj"></el-input>
        </el-form-item>

        <el-form-item label="数学成绩" prop="shx_chj">
          <el-input type="number" placeholder="请输入数学成绩" v-model="rulesForm.shx_chj"></el-input>
        </el-form-item>

        <el-form-item label="综合成绩" prop="zh_chj">
          <el-input type="number" placeholder="请输入综合成绩" v-model="rulesForm.zh_chj"></el-input>
        </el-form-item>

        <el-form-item label="外语成绩" prop="wy_chj" v-if="rulesForm.jh_lb == '单列类（选考外语）'">
          <el-input type="number" placeholder="请输入外语成绩" v-model="rulesForm.wy_chj"></el-input>
        </el-form-item>

        <el-form-item label="民族语文成绩" v-if="rulesForm.jh_lb == '单列类（选考民族语文）'" prop="mzyw_chj">
          <el-tag type="text">备注:选考外语考生不用填写</el-tag>
          <el-input type="text" placeholder="请输入民族语文成绩" v-model="rulesForm.mzyw_chj"></el-input>
        </el-form-item>

        <el-form-item label="专业意向" prop="zy_yx">
          <el-input type="textarea" v-model="rulesForm.zy_yx"></el-input>
        </el-form-item>
        <div style="padding-top: 20px; padding-bottom: 30px">
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
            <el-button @click="reset">重置</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
export default {
  name: "App",
  data() {
    return {
      loading: false,
      rulesForm: {
        real_name: "",
        ksh_zhkzh: "",
        phone: "",
        fumu_phone: "",
        gender: "1",
        address: "",
        by_gz: "",
        jk_zhk: "",
        lx_dx: "",
        xw_chs: "",
        hobby: "",
        xk_lb: "理工类",
        jh_lb: "单列类（选考外语）",
        gk_zchj: "",
        gk_wc: "",
        yw_chj: "",
        shx_chj: "",
        zh_chj: "",
        wy_chj: "",
        mzyw_chj: "",
        zy_yx: "",
        jt_qk: "",
      },
      rules: {
        real_name: [{ required: true, message: "请输入考试姓名", trigger: "blur" }],

        ksh_zhkzh: [{ required: true, message: "请输入考生准考证号", trigger: "blur" }],

        phone: [
          { required: true, message: "请输入考生联系电话", trigger: "blur" },
          {
            validator: (item, value, callback) => {
              if (value.length != 11) {
                return callback(new Error("手机号不正确"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],

        fumu_phone: [
          { required: true, message: "请输入考生父母联系电话", trigger: "blur" },
          {
            validator: (item, value, callback) => {
              if (value.length != 11) {
                return callback(new Error("手机号不正确"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],

        gender: [{ required: true, message: "请选择考生性别", trigger: "change" }],

        address: [{ required: true, message: "请输入考生所在地", trigger: "blur" }],

        jk_zhk: [{ required: true, message: "请输入家庭经济情况", trigger: "blur" }],
        by_gz: [{ required: true, message: "请输入考生毕业高中", trigger: "blur" }],

        xk_lb: [{ required: true, message: "请选择学科", trigger: "change" }],

        jh_lb: [{ required: true, message: "请选择计划类别", trigger: "change" }],
        jt_qk: [{ required: true, message: "请输入考生健康状况", trigger: "blur" }],

        gk_zchj: [{ required: true, message: "请输入高考总成绩", trigger: "blur" }],
        hobby: [{ required: true, message: "请输入考生性格爱好", trigger: "blur" }],
        gk_wc: [{ required: true, message: "请输入高考位次", trigger: "blur" }],
        mzyw_chj: [{ required: true, message: "请输入民族语文成绩", trigger: "blur" }],

        lx_dx: [{ required: true, message: "请输入考生理想大学", trigger: "blur" }],
        xw_chs: [{ required: true, message: "请输入考生向往城市", trigger: "blur" }],

        yw_chj: [{ required: true, message: "请输入语文成绩", trigger: "blur" }],

        shx_chj: [{ required: true, message: "请输入数学成绩", trigger: "blur" }],

        zh_chj: [{ required: true, message: "请输入综合成绩", trigger: "blur" }],

        wy_chj: [{ required: true, message: "请输入外语成绩", trigger: "blur" }],

        zy_yx: [{ required: true, message: "请输入专业意向", trigger: "blur" }],
      },
    };
  },
  methods: {
    submitForm(formName) {
      let id = 0;
      try {
        const urlParams = new URLSearchParams(window.location.search);
        id = urlParams.get("id");
      } catch (e) {
        console.log(e);
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //如果校检通过，在这里向后端发送用户名和密码
          const data = this.rulesForm;
          this.loading = true;
          Axios.post(
            `https://smart.mulazim.com/zh/v1/college/mini/enroll/experts/${id}/enrolls`,
            data
          ).then((res) => {
            this.loading = false;
            if (res.data.status != 200) {
              this.$message.error(res.data.msg);
              return;
            }
            this.$alert(res.data.msg, "报名成功", {
              confirmButtonText: "确定",
              type: "success",
              center: true,
            });
            this.$refs.ruleForm.resetFields();
          }).catch(error => {
            this.loading = false;
            console.log(error);
            // 请求失败后的处理逻辑
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    reset() {
      this.$refs.ruleForm.resetFields();
    },
  },
  components: {},
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100% !important;
  height: auto;
}

.haeder {
  height: 220px;
}

.haeder img {
  width: 100%;
  height: 100%;
}

.el-input {
  font-size: 24px !important;
}

.body {
  margin: 10px 20px;
}

.el-form-item {
  margin-bottom: 16px !important;
}

.el-form--label-top .el-form-item__label {
  padding: 0 0 1px !important;
  font-size: 24px !important;
}

.el-form-item__error {
  font-size: 20px !important;
}

.el-radio__inner {
  width: 24px !important;
  height: 24px !important;
}

.el-checkbox__inner::after {
  left: 7px !important;
  top: 4px !important;
}

.el-textarea {
  font-size: 24px !important;
}

.el-textarea__inner {
  color: #000 !important;
}

.el-tag {
  font-size: 16px !important;
}

.el-avatar,
.el-cascader-panel,
.el-radio,
.el-radio--medium.is-bordered .el-radio__label,
.el-radio__label {
  font-size: 18px !important;
}

.el-checkbox__inner {
  width: 20px !important;
  height: 20px !important;
}

.el-input__inner {
  height: 55px !important;
  line-height: 55px !important;
}

.el-checkbox__label {
  font-size: 18px !important;
}

.el-message-box {
  width: 90% !important;
}
.el-loading-mask {
    position: fixed !important;
}
</style>
